<template>
  <router-view :key="$route.fullPath" />
</template>

<script>
export default {
  created() {
    this.$store.commit("verticalMenu/SET_ADMIN_NAVIGATION");
    this.$store.commit("farm/RESET_STATE");
    this.$store.commit("cooperative/RESET_STATE");
    this.$store.commit("iaa/RESET_STATE");
  }
};
</script>
